.header-app-logo {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;

  .main-logo {
    height: 64px;
    width: auto;
  }

  .partner-logo {
    width: 100px !important;
    height: auto;
    cursor: pointer;
  }

  .powered-by-logo {
    width: auto;
    height: 48px;
    cursor: pointer;
  }


  &.large {

    flex-wrap: wrap !important;

    .brand-logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .partner-logo {
        width: min(200px, 100%) !important;
      }

      @media screen and (min-width: 678px) {
        display: contents;
        width: auto;
      }
    }

    @media screen and (min-width: 678px) {
      .partner-logo {
        width: 150px !important;
      }
    }
  }

  &.vertical {
    flex-direction: column;
  }

  &.horizontal {
    flex-direction: row;
  }

  @media screen and (max-width: 380px) {

    .partner-logo {
      width: 80px !important;
    }

    .powered-by-logo {
      height: 36px;
    }
  }
}