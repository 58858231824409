@import '../../variables.scss';

.section-loader {
  .spinner-border {
    display: inline-block;
    width: 3.5rem;
    height: 3.5rem;
    vertical-align: -0.125em;
    border: 0.45em solid $primary-color;
    border-right-color: transparent;
    border-radius: 50%;
  }
}