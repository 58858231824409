@font-face {
  font-family: "satoshi";
  src: url("/public/assets/fonts/satoshi/Satoshi-Variable.ttf")
    format("truetype");
}

@font-face {
  font-family: "satoshi-medium";
  src: url("/public/assets/fonts/satoshi/Satoshi-Medium.otf") format("opentype");
}


