@import '../../variables.scss';

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;

  .spinner-border {
    display: inline-block;
    width: 5.5rem;
    height: 5.5rem;
    vertical-align: -0.125em;
    border: 0.6em solid $primary-color;
    border-right-color: transparent;
    border-radius: 50%;
  }

  .loading-text {
    font-size: 1.2rem;
    color: $heading-font-color;
  }
}