.account-layout {
  display: flex;
  background-color: #fafbfe;
  min-height: 100vh;
  width: 100%;

  .open-width {
    width: calc(100% - 300px);
  }

  .close-width {
    width: calc(100% - 150px);
  }

  .account-page,
  .error-page {
    min-height: calc(100vh - 100px);
  }

}

@media screen and (min-width: 576px) {
  .account-page {
    max-width: 95%;
  }
}

@media screen and (max-width: 990px) {
  .account-layout {
    .side-nav-wrapper {
      position: absolute;
      z-index: 101;

      .open-shadow {
        box-shadow: 0 0 0 2000px rgba(0, 0, 0, 0.523);
      }
    }

    .open-width {
      width: 100%;
    }

    .close-width {
      width: 100%;
    }
  }
}