:root {
  --primary-color: #a429d9;
  --primary-color-light: #a984da;
  --primary-color-extra-light: #f3eaff;
  --primary-color-dark: #8112b0;
  --primary-shadow-color: #7500a7;
  --primary-button-text-color: #ffffff;

  --secondary-color: #05c7f2;
  --secondary-color-light: #b1e2ed;
  --secondary-color-extra-light: #e6f9ff;
  --secondary-color-dark: #00687f;
  --secondary-shadow-color: #009fc2;
  --secondary-button-text-color: #ffffff;

  --accent-color: #f2c94c;

  --header-font-color: #0a174c;
  --body-font-color: #586185;

}

$custom-gray-color: #586185;
$heading-font-color: var(--header-font-color);
$body-font-color: var(--body-font-color);
$primary-color: var(--primary-color);
$primary-color-light: var(--primary-color-light);
$primary-color-extra-light: var(--primary-color-extra-light);
$primary-shadow-color: var(--primary-shadow-color);
$primary-color-dark: var(--primary-color-dark);
$primary-button-text-color: var(--primary-button-text-color);

$secondary-color: var(--secondary-color);
$secondary-color-light: var(--secondary-color-light);
$secondary-color-extra-light: var(--secondary-color-extra-light);
$secondary-color-dark: var(--secondary-color-dark);
$secondary-shadow-color: var(--secondary-shadow-color);
$secondary-button-text-color: var(--secondary-button-text-color);

$accent-color: var(--accent-color);

$satoshi-medium: "satoshi-medium" !important;