@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "./variables";

@import "../node_modules/bootstrap/scss/bootstrap";

body {
  background-color: $secondary-color-extra-light;
  font-family: 'satoshi', sans-serif;
  color: $body-font-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-font-color;
}

.App {
  min-height: 100vh;
  text-align: center;
}

.title {
  font-weight: 500;
  font-size: xx-large;
  color: $heading-font-color;
  line-height: 1.2;
}

.subtitle {
  font-size: 15px;
}

.headline {
  font-size: 3rem;
  font-weight: 600;
  color: #0a174c;
  line-height: 1;
}


.heading-color {
  color: $heading-font-color;
}

.custom-gray-font {
  color: $custom-gray-color;
}

.round-button {
  border-radius: 50%;
  padding: 10px;
  border: none;
  background-color: #ced1db;
  box-shadow: 0px 2px rgb(100, 100, 100);
}

.form-label {
  font-weight: 700;
  color: $heading-font-color;
}

.form-control {
  padding: 16px;
}

.primary-button {
  background-color: $primary-color;
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 20px;
  box-shadow: 0px 6px $primary-shadow-color;
  color: $primary-button-text-color;

  &:hover {
    color: $primary-button-text-color;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.primary-outline-button {
  border: 2px $primary-color solid;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 12px 20px;
  background: transparent;
  color: $primary-color;
}

.primary-transparent-button {
  border: 0;
  border-radius: 8px;
  font-weight: 700;
  font-size: 20px;
  padding: 0;
  background: transparent;
  color: $primary-color;
}

.secondary-button {
  background-color: $secondary-color;
  border: 0;
  border-radius: 8px;
  padding: 12px 20px;
  font-weight: 700;
  font-size: 20px;
  box-shadow: 0px 6px $secondary-shadow-color;
  color: $secondary-button-text-color;

  &:hover {
    color: $secondary-button-text-color;
  }

  &:disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }
}

.nav-link-selected {
  background-color: $primary-color;
  color: white;
  min-width: 175px;
  border: 0;
  border-radius: 8px;
  font-size: 16px;
  padding: 12px;
}

a {
  color: $primary-color;
  font-weight: 600;
  text-decoration: none;
  transition: 0.2s ease color;

  &:hover {
    color: $primary-color-dark;
  }
}

.link-button {
  text-decoration: none;

  &:hover {
    color: white;
  }
}

input,
.form-control {

  &:focus {
    background-color: rgba(247, 231, 253, 0.286);
    box-shadow: 0px 0px 0px 1px $primary-color inset !important;
    border: 1px solid $primary-color;
  }

  &:disabled {
    background-color: rgba(247, 231, 253, 0.286);
  }
}

@media (max-width: 575px) {
  .profile-picture-container .primary-button {
    margin: 16px 0px;
  }

  .profile-picture-container .primary-transparent-button {
    margin-right: 10px;
  }
}

.border-blue {
  border: 1px solid #05c7f2;
}

.border-orange {
  border: 1px solid #fa8431;
}

.border-yellow {
  border: 1px solid #ffb000;
}

@include media-breakpoint-down(sm) {
  .min-w-100 {
    min-width: 100% !important;
  }

  .pt-2-sm {
    padding-top: 0.5rem !important;
  }
}

@include media-breakpoint-up(sm) {
  .w-sm-25 {
    width: 25% !important;
  }

  .w-sm-50 {
    width: 50% !important;
  }

  .w-sm-75 {
    width: 75% !important;
  }

  .w-sm-100 {
    width: 100% !important;
  }
}

@include media-breakpoint-up(md) {
  .w-md-auto {
    width: auto !important;
  }

  .w-md-25 {
    width: 25% !important;
  }

  .w-md-30 {
    width: 30% !important;
  }

  .w-md-35 {
    width: 35% !important;
  }

  .w-md-40 {
    width: 40% !important;
  }

  .w-md-50 {
    width: 50% !important;
  }

  .w-md-60 {
    width: 60% !important;
  }

  .w-md-65 {
    width: 65% !important;
  }

  .w-md-70 {
    width: 70% !important;
  }

  .w-md-75 {
    width: 75% !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .w-lg-75 {
    width: 75% !important;
  }

  .decorative-img-md-1 {
    top: -80px !important;
    left: -80px !important;
  }

  .decorative-img-md-2 {
    top: -90px !important;
    right: -80px !important;
  }

  .decorative-img-md-3 {
    bottom: -50px !important;
    right: -10px !important;
  }

  .decorative-img-md-4 {
    bottom: -50px !important;
    left: -20px !important;
  }
}

@include media-breakpoint-up(lg) {

  .w-lg-25 {
    width: 25% !important;
  }

  .w-lg-30 {
    width: 30% !important;
  }

  .w-lg-70 {
    width: 70% !important;
  }
}

@include media-breakpoint-up(xl) {
  .w-xl-25 {
    width: 25% !important;
  }

  .w-xl-30 {
    width: 30% !important;
  }

  .w-xl-70 {
    width: 70% !important;
  }
}

.alert-box {
  .modal-dialog {
    .modal-content {
      border: none;
      border-radius: 16px;
      padding: 40px 32px;
    }
  }
}

.secondary-text {
  color: $secondary-color;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.form-check-label {
  color: $heading-font-color;
  font-weight: 600;
}

.form-check-input[type="checkbox"] {
  border-radius: 0.25em;
  border: 0;
  padding: 4px;
  box-shadow: 0 0 0 2px $heading-font-color;

  &:focus {
    box-shadow: 0 0 0 2px $heading-font-color;
  }
}

.form-check-input:checked {
  background-color: $heading-font-color;
}

.rotate-180 {
  transform: rotateZ(180deg);
  transition: 0.2s ease;
}

.rotate-0 {
  transform: rotateZ(0deg);
  transition: 0.2s ease;
}

.recharts-tooltip-wrapper {
  border: none;
  outline: none;
  height: auto;
  width: auto;
}

.custom-multi-select {
  input {

    &:focus {
      box-shadow: none !important;
    }
  }

  .react-select__control {
    border-radius: 8px !important;
    min-height: 52px;
    border: 1px solid #ced1db;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: #313c69;

    .react-select__value-container {
      height: 100% !important;
      padding: 0 8px !important;

      .react-select__placeholder {
        text-align: left;
      }
    }

    .css-jzldcf-Input {
      margin: 0 !important;
      padding: 0 !important;
    }

    .css-6j8wv5-Input {
      margin: 0 !important;
      padding: 0 !important;
    }
  }

  .react-select__menu {
    .react-select__menu-list {
      .react-select__option {
        text-align: left;
      }
    }
  }
}

.tag {
  height: 42px;
  padding: 8px 12px;
  width: fit-content;
  border-radius: 10px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  white-space: nowrap;
}

.file-tag {
  background: #fbf6fd;
  color: #a429d9;
}

.submit-tag {
  background: #fff2e2;
  color: #d98a29;
}

.name-tag {
  background: #eff8fc;
  color: #05c7f2;
}

.competition-cards-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .competition-card {
    width: auto;
    min-width: auto;

    .card-banner {
      width: 100%;
    }
  }
}

@media screen and (max-width: 1150px) {
  .sidebar-open {
    .competition-cards-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .sidebar-close {
    .competition-cards-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .search-div {
    .banner-image {
      display: none !important;
    }
  }
}


@media screen and (max-width: 1000px) {
  .competition-cards-grid {
    grid-template-columns: repeat(2, 1fr) !important
  }
}


@media screen and (max-width: 768px) {
  .competition-cards-grid {
    display: flex !important;
    flex-direction: column !important;
  }

}

.scroll-margin-top-100 {
  scroll-margin-top: 100px;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.floating-button-platform-switcher {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 2000;

  button {
    border-radius: 50px;
    padding: 10px 16px;
    border: none;
    background-color: #a429d9;
    box-shadow: 0px 1px 15px rgb(0 0 0);
    font-weight: 600;
    color: white;
  }
}

.cursor-hidden {
  cursor: none !important;
}

.cursor-default {
  cursor: default !important;
}

.line-height-2 {
  line-height: 2;
}


.decoration-img-container {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: clip;

  .decorative-img {
    width: 250px;
    height: 250px;
    position: absolute;
    object-fit: contain;

    &-1 {
      top: -50px;
      left: -60px;
      transform: rotateZ(45deg);
    }

    &-2 {
      bottom: -40px;
      right: -60px;
      transform: rotateZ(-45deg);
    }

    @media (max-width: 1200px) {

      &-1 {
        top: -10px;
      }

    }

    @media (max-width: 992px) {
      width: 180px;
      height: 180px;

      &-1 {
        top: -10px;
        left: -60px;
      }

      &-2 {
        right: -60px;
      }
    }

    @media (max-width: 768px) {
      width: 140px;
      height: 140px;

      &-1 {
        top: -10px;
        left: -40px;
      }

      &-2 {
        right: -40px;
      }
    }

    @media (max-width: 576px) {
      width: 120px;
      height: 120px;

      &-1 {
        left: -40px;
        top: -10px;
      }

      &-2 {
        right: -20px;
        bottom: -30px;
      }
    }
  }
}

.text-justify {
  text-align: justify;
  text-justify: inter-word;
}

.text-orange {
  color: orange;
}

.overflow-sm-hidden {
  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}

.inherit-font {
  font-size: inherit;
  font-weight: inherit;
}

.bg-primary-extra-light {
  background-color: $primary-color-extra-light;
}

.primary-colored-text {
  color: $primary-color;
}